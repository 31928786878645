.Import {
  top: 0;
  text-align: center;
  &__template_dropdown {
    .MuiInputLabel-root {
      color: #696969 !important;
    }
  }

  &__dropdownArrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    margin-right: 0.3rem;
    border-top: 5px solid rgb(116, 116, 116);
  }
  &__fileUpload {
    border: 1px dashed black;
    padding-top: 1.7rem !important;
    width: 100%;
    height: 6rem;
    cursor: pointer;
    margin-bottom: 0.3rem !important;
    &__input {
      display: none;
    }
  }
  // .info-content-import {
  //   width: 150px;
  //   word-break: normal;

  // }
  &__radioType {
    &__padding {
      padding: 0 5px 32px;
    }
    &__option {
      width: calc(50% - 47px);
      justify-content: flex-start !important;
    }
    &__list {
      width: 100%;
      height: auto;
      max-height: calc(85vh - 80px);
      overflow-y: auto;
    }
    &__heading {
      height: 56px;
      border-radius: 10px;
      border: solid 1px #dcdcdc;
      background-color: #fafcff;
      padding-left: 20px;
      display: flex;
      justify-content: space-between;
      padding-right: 20px;
      align-items: center;
      margin-top: 20px;
    }
    &__label {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
  }

  &____AddFormat {
  }
  &__formatName {
    margin-bottom: 1.6rem !important;

    &__btn {
      width: 6rem !important;
    }
  }
}
.import_dropdown {
  .MuiFormLabel-root {
    color: #696969 !important;
  }
}
.importFormatSelector {
  width: 100%;
  overflow-y: auto;
  white-space: nowrap !important;
  max-height: 40vh !important;
  overflow-x: hidden;
}
