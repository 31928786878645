.task-detail {
  width: 100%;
  // height: 100vh;
  position: relative;
  //   top: 70px;
  display: flex;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 4ms;
  //   transition: all 0.3s ease-out;
  transform-origin: 100% 50%;
  &__map-view {
    width: 70%;
  }
  &__data-view {
    width: 30%;
    height: calc(100vh - 75px);
    // overflow-y: scroll;
    overflow: hidden;
  }
  &__header {
    // background-color: #e7e7e7;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__close-icon {
    padding: 4px 4px 4px 4px;
    background-color: #c0c0c0;
    border-radius: 100px;
    display: flex;
    &:hover {
      cursor: pointer;
    }
  }
}
.taskDetailMap {
  height: calc(100vh - 75px);
  // width: 70vw;
  font-size: 16px;
}
.popupText{
  margin: 0!important;
  // font-weight:bold;
  opacity:0.7;
  font-size: 14px;
} 
.popupTextValue{
  margin: 0!important;
  // font-weight:bold;
  opacity:1;
  font-size: 14px;
} 
@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 100px;
  }
}
