@import "../../utils/mixins.scss";

.dashboardMap {
  height: calc(100vh - 70px);
  width: 100%;
  // font-family: Roboto;
  font-size: 16px;
}
.relasenotes_popup {
  height: 62vh;
  position: absolute;
  background-color: white;
  border-radius: 15px;
  right: 75px;
  top: 90px;
  z-index: 800 !important;
  min-width: 380px;

  max-width: 400px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.relasenotes_popup.arrow-top:after {
  content: " ";
  position: absolute;
  right: 30px;
  top: -15px;
  border-top: none;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 15px solid white;
}
#createJobButtonContainer {
  position: absolute;
  top: 100px;
  right: 20px;
  cursor: pointer;
  z-index: 400;
  width: 130px;
  padding: 4px 0px 4px 4px;
  background-color: #ffffff;
  border-radius: 0.5rem;
  height: 2.4rem;
  .createJobButtonContainer__circle {
    width: 2.4rem;
    height: 2.4rem;
    display: inline-flex;
    background-color: var(--primary-theme) !important;
    border-radius: 22%;
    justify-content: center;
    text-align: center;
    align-items: center;
    cursor: pointer;
  }
  .createJobButtonContainer__text {
    display: inline-flex;
    font-size: 0.8rem;
    align-items: center;
    height: 2.4rem;
    position: absolute;
    right: 10px;
  }
  .createJobButtonContainer__routeIcon {
    width: 2.4rem;
    height: 2.4rem;
  }
  // margin-right: 10%;
}
#createJobMarketDetailContainer {
  z-index: 400;
}
/* required styles */
.tooltip-row {
  padding-right: 10px;
  padding-bottom: 10px;
  display: flex;
  margin-left: 10px;
}
.status-circle-dot {
  width: 16px;
  height: 16px;
}
.tooltip-column {
  display: inline-flex;
  padding-left: 10px;
}
.tooltip-row .info-text {
  padding-left: 8px;
  font-size: 0.9rem;
}

.createJobButtonOnMap:hover {
  background-color: #ffffff;
}
.ReleaseUpdate {
  padding: 10px 15px;

  &__dot {
    height: 9px;
    width: 9px;
    background-color: var(--primary-theme);
    border-radius: 50%;
    display: inline-block;
  }
}
.update-dot {
  height: 9px;
  width: 9px;
  background-color: red;
  position: absolute;
  right: -1px;
  border-radius: 50%;
  display: inline-block;
}
.infinte-scroll-updates {
  overflow-x: hidden !important;
  max-height: 55vh;
}
