.CreateJob {
  width: 43em;

  &:focus {
    outline: none;
  }
  &__assign-agent {
    min-height: calc(85vh - 80px);
    // overflow-y: hidden;
  }
  .MuiTabs-flexContainer {
    display: flex;
    border-bottom: solid 2px #e9e9e9;
    margin-right: 1rem;
  }
  .MuiAccordion-root.Mui-expanded {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  &__form {
    // height: auto;
    // max-
    // overflow-y: auto;
    &__job-type {
      width: calc(50% - 47px);
      justify-content: flex-start !important;
    }
    &__recurring {
      // margin-top: 12px;
      margin-left: 1rem;
      margin-right: 1rem;
      &__scheduleName {
        margin-top: 5px;
      }
      &__scheduleCycle {
        margin-top: 4px;
        .MuiOutlinedInput-input {
          padding: 16.5px 14px;
        }
      }
    }
    &__assign-accordion {
      margin-top: 20px;
      margin-left: 1rem;
      margin-right: 1rem;
      .MuiOutlinedInput-input {
        padding: 15.5px 14px;
      }
    }
    &__task-accordion {
      // margin-top: 20px;
      // margin-top: -10px;
      margin-left: 1rem;
      margin-right: 1rem;
      // margin-bottom: 0.8rem;
      &__date-input {
        width: calc(100% - 28px);
        padding: 18.5px 14px;
        height: 0.7em;
        // margin-top: 6px;
        margin-bottom: 6px;
        border-radius: 10px;
        border-color: rgba(0, 0, 0, 0.23);
        border-style: solid;
        border-width: 1px;
        color: var(--input-text-color);
        font-size: 16px;
        font-family: "Lato";
        outline-color: var(--primary-theme);
        outline-offset: -1px;
      }
      &__delete-icon {
        background: none;
        border: none;
        cursor: pointer;
      }
      &__job-type-collapse-heading {
        // margin-right: 10px;
        display: flex;
        align-items: center;
        font: normal normal 900 18px/35px Lato;
        margin-left: 5px;
        letter-spacing: 0.21px;
        font-weight: 800;
        // margin-bottom: 25px;
      }
      &__job-type-heading {
        // margin-right: 10px;
        display: flex;
        width: 200px;
        align-items: center;
        font: normal normal 900 23px/35px Lato !important;
        margin-left: -15px;
        letter-spacing: 0.21px;
        font-weight: 800 !important;
        margin-bottom: -15px;
      }
      &__recurring-type-heading {
        // margin-right: 10px;
        display: flex;
        align-items: center;
        font: normal normal 900 23px/35px Lato;
        // margin-left: -15px;
        letter-spacing: 0.21px;
        font-weight: 800;
        // margin-top: -10px;
        margin-bottom: 15px;
      }
      &__assign-type-heading {
        display: flex;
        align-items: center;
        font: normal normal 900 23px/35px Lato;
        margin-left: -15px;
        letter-spacing: 0.21px;
        font-weight: 800;
        margin-right: 10px;
        margin-bottom: 6px;
        margin-left: 2px;
      }
      &__heading-detail {
        width: 100%;
        &__container {
          display: flex;
          align-items: center;
        }
      }
      &__see-on-map {
        position: absolute;
        right: 2px;
        margin-top: 16px;
        padding-left: 10px !important;
        padding-right: 20px;
        color: var(--primary-theme);
        cursor: pointer;
        z-index: 410;
        background: #fff;
        display: flex;
        align-items: center;
      }
      &__latlng-on-map {
        position: absolute;
        right: 55px;
        top: 15px;
        padding-left: 5px !important;
        // padding-right: 20px;
        color: var(--primary-theme);
        cursor: pointer;
        z-index: 410;
        background: #fff;
        // width:50px
      }
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
      // padding: 8.5px;
    }
    &__padding {
      padding-left: 32px;
      padding-right: 32px;
    }
    &__jobid {
      padding-left: 32px;
      padding-right: 32px;
      font-weight: normal;
    }
    &__add-first-job-type-heading {
      // min-width: 620px;
      height: 56px;
      border-radius: 20px;
      // border: solid 1px #dcdcdc;
      background-color: #fafcff;
      box-shadow: 0 18px 18px 0 rgba(0, 126, 191, 0.26);
      font: normal normal 900 18px/35px Lato;
      font-weight: bold;
      // cursor: pointer;
      padding-left: 20px;
      margin-right: 1rem;
      margin-left: 1rem;
      display: flex;
      justify-content: space-between;
      padding-right: 20px;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 2rem;
    }
    &__add-another-job-type-heading {
      margin-top: 20px;
      display: flex;
      cursor: pointer;
      align-items: center;
      &__text {
        margin-left: 10px;
      }
    }
    &__footer {
      // text-align: end;
      margin-bottom: 20px;
      margin-right: 1rem;
      margin-left: 1rem;
      &__edit-button {
        height: 36px;
        background: white;
      }
      &__create-button {
        // width: 100%;
        height: 36px;
        // margin-top: 20px;
      }
    }
    &__task-list {
      // height: auto;

      &__leftA {
        height: calc(76vh - 80px) !important;
        overflow-y: auto;
      }
    }
    &__reassign {
      height: auto;
      // max-height: calc(85vh - 80px);
      overflow-y: auto;
    }
    &__auto-allocation {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  &__repeatOn {
    margin-top: 10px;
    &__repeat-heading {
      margin-top: 12px;
      margin-bottom: 5px;
    }
    &__repeat-days {
      cursor: pointer;
      border-radius: 50%;
      background: var(--white-color);
      padding: 6px;
      color: var(--black-color);
      text-align: center;
      justify-content: center;
      display: inline-flex;
      height: 13px;
      width: 13px;
      align-items: center;
      margin-right: 5px;
      border: 1px solid var(--border-color);
    }
    &__repeat-days-selected {
      cursor: pointer;
      background: var(--primary-theme);
      color: #fff;
      // border: none;
      border-radius: 50%;
      padding: 6px;
      text-align: center;
      justify-content: center;
      display: inline-flex;
      height: 13px;
      width: 13px;
      border: 1px solid var(--border-color);
      align-items: center;
      margin-right: 5px;
    }
    &__schedule-criteria-label {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }
    &__schedule-no-of-occurences {
      margin-top: 6px;
    }
  }
}
.Mui-expanded.MuiAccordionSummary-expandIcon {
  color: var(--primary-theme);
}
.resource-dropdown .MuiAutocomplete-popper {
  margin-top: 40px;
}
.resource-dropdown {
  margin-top: 0px;
  margin-bottom: 10px;
}
.CreateJob .MuiGrid-item {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.CreateJob .MuiCollapse-hidden {
  // visibility: hidden;
  display: none;
}
.Mui-selected {
  color: black;
}
.CreateJob .template-data {
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05) !important;
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
  padding: 25px !important;
}
.CreateJob #panel1bh-header .MuiAccordionSummary-content {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 375px) {
  .CreateJob {
    width: 80%;
    &:focus {
      outline: none;
    }
    &__form {
      margin: auto;
      width: 100%;

      &__recurring {
        // margin-top: 12px;
        margin-left: 0.2rem;
        &__scheduleName {
          margin-top: 5px;
        }
        &__scheduleCycle {
          margin-top: 4px;
        }
      }
      &__task-accordion {
        margin-top: 20px;
        margin-left: 1rem;

        &__delete-icon {
          background: none;
          border: none;
          cursor: pointer;
        }
        &__job-type-heading {
          margin-right: 10px;
          display: flex;
          align-items: center;
        }
        &__heading-detail {
          width: 100%;
          padding: 17px;
          &__container {
            display: flex;
            align-items: center;
          }
        }
        &__see-on-map {
          position: absolute;
          right: 1px;
          margin-top: 16px;
          padding-right: 20px;
          padding-left: 5px !important;
          color: var(--primary-theme);
          cursor: pointer;
          z-index: 410;
          background: #fff;
          display: flex;
          align-items: center;
        }
      }

      &__add-first-job-type-heading {
        height: 56px;
        border-radius: 10px;
        border: solid 1px #dcdcdc;
        background-color: #fafcff;
        padding-left: 20px;
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
        align-items: center;
        margin-top: 20px;
      }
      &__add-another-job-type-heading {
        margin-top: 20px;
        display: flex;
        cursor: pointer;

        align-items: center;
        &__text {
          margin-left: 10px;
        }
      }
      &__footer {
        text-align: center;
        margin-bottom: 15px;
        &__edit-button {
          height: 50px;
          background: white;
        }
        &__create-button {
          width: 100%;
          height: 50px;
          margin-top: 20px;
        }
      }
      &__task-list {
        height: auto;
        max-height: calc(85vh - 80px);
        overflow-y: auto;
      }
      &__reassign {
        height: auto;
        // max-height: calc(85vh - 80px);
        overflow-y: auto;
      }
      &__auto-allocation {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }

  .input__box-container {
    width: 100%;
  }
}
