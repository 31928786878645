.createjob-info_popup{
    max-height:60vh;
    position: absolute;
    background-color:white;
    border-radius: 15px;
    // right: 65px;
    top: 150px;
    margin-left: 25px;
    z-index: 1301!important;
    min-width: 250px;
    max-width: 250px;
    // max-width: 400px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    
  }
  .createjob-info_popup.arrow-top:after {
    content: " ";
  position: absolute;
  left: -15px;
  top: 30px;
  border-top: 12px solid transparent;
  border-right: 15px solid white;
  border-left: none;
  border-bottom: 15px solid transparent;
  }
  
  .CreateJobInfo{
      text-align: center;
      margin:20px 0;
      
      &__dottedpath {
        min-height: 20px;
        border-left: 2px dashed lightgrey;
        margin: -8px 0 -8px 8px

      }
      &__next-button{
       
        min-width: 110px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px
      }
      &__radioLabel{
        font-weight: bold;
        font-size: 15px;
      }
      &__radioLabelInfo{
        font-size: 14px;
        color:rgb(112, 112, 112)
      }
      &__info-div{
        margin: -10px 22px;
        text-align: left
      }
      
  }
  .details-popup{
    max-height:60vh;
    // position: absolute;
    background-color:white;
    border-radius: 15px;
   
    z-index: 1301!important;
    min-width: 250px;
    max-width: 250px;
    // max-width: 400px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
