@import url("https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.css");
.networkOnMap .leaflet-popup-content-wrapper,
.networkOnMap
  .leaflet-popup:not(.task-info-popup)
  .leaflet-popup-tip-container
  .leaflet-popup-tip {
  color: var(--black-color);
  background: var(--white-color);
}
.networkOnMap.dark-map
  .leaflet-popup:not(.task-info-popup)
  .leaflet-popup-content-wrapper,
.networkOnMap.dark-map
  .leaflet-popup:not(.task-info-popup)
  .leaflet-popup-tip-container
  .leaflet-popup-tip {
  color: #3c3c3c;
  background: var(--white-color);
  border-radius: 4px;
}
.cluster-icon {
  background-image: url("/app/img/cluster-marker.svg");
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}
.dark-map .cluster-icon {
  background-image: url("/app/img/cluster-marker-blue.svg");
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}
.tag-label {
  border-radius: 20px;
  background: #707070;
  color: white;
  border-width: 2px;
  border-color: #eee;
  margin-top: -6px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -5px;
  height: 20px;
}
.cluster-icon i.badge {
  float: left;
  background-color: #d23d29;
  font-style: normal;
  background-color: transparent;
  font-style: normal;
  color: black;
  text-align: center;
  display: flex;
  width: 70%;
  text-align: center;
  justify-content: center;
  margin-top: 10px;
}
.cluster-icon-bg {
  border: 0;
  z-index: 9999 !important;
  background-color: transparent;
}
.task-info-popup {
  box-shadow: none;
  margin: 10px !important;
  left: 0 !important;
  transition: none !important;
}
.task-info-popup.cluster {
  min-width: 200px;
}
.task-info-popup.cluster .popup-contianer {
  max-height: 200px;
  overflow: auto;
  flex-direction: column;
  align-items: flex-start;
}
.task-info-popup.cluster .popup-contianer > div {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid #eee;
  min-height: 35px;
  align-items: center;
}
/* .task-info-popup .leaflet-popup-tip-container{
    display: none;
} */
.leaflet-popup-content-wrapper {
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.19);
}
.dark-map .task-info-popup .leaflet-popup-content-wrapper {
  background-color: var(--black-color) !important;
  color: var(--white-color) !important;
}
.popup-contianer > div > div {
  margin-bottom: 10px;
}
.task-info-popup .leaflet-popup-content-wrapper {
  background-color: var(--white-color) !important;
  color: var(--black-color) !important;
}
.leaflet-popup-tip {
  background-color: var(--white-color) !important;
  border-radius: 5px;
  height: 15px;
  width: 14px;
}
.dark-map .leaflet-popup-tip {
  background-color: var(--white-color) !important;
}
.task-info-popup .leaflet-popup-content {
  margin: 0;
}
.task-info-popup .leaflet-popup-content > div.popup-contianer {
  // margin: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px;
  // margin-top: 15px;
}
.task-info-popup .leaflet-popup-content i {
  font-size: 18px;
  margin-right: 4px;
}

.leaflet-resp-popup-west {
  margin-left: -10px !important;
  margin-top: -12px !important;
}
.leaflet-resp-popup-north {
  margin-left: 5px !important;
  margin-top: 5px !important;
}
.leaflet-resp-popup-west-south {
  margin-top: -17px !important;
  margin-left: -10px !important;
}
.leaflet-resp-popup-south {
  margin-left: 0px !important;
  margin-top: -26px !important;
}
.leaflet-control-container {
  position: absolute;
  bottom: 100px;
}
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
  width: 12px !important;
  height: 12px !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 15px;
  border-color: rgb(255, 0, 0);
  background-color: white;
  margin-top: -8px !important;
  // margin-left: 0px !important;
}
.leaflet-draw-tooltip {
  background: #363636;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid transparent;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  color: #fff;
  font: 12px/18px "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin-left: 20px;
  margin-top: -21px;
  padding: 4px 8px;
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
  z-index: 6;
}
.leaflet-draw-tooltip:before {
  border-right: 6px solid black;
  border-right-color: rgba(0, 0, 0, 0.5);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  content: "";
  position: absolute;
  top: 7px;
  left: -7px;
}
