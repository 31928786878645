// .verticalBar ul {
//   counter-reset: list;
//   border-left: 2px solid lightgrey;
//   margin: 1em;
//   padding: 0;
// }
// .verticalBar ul li {
//   list-style: none;
//   margin: 0 0 0.5em 0;
//   padding: 0;
// }
// .verticalBar ul li::after {
//   display: inline-block;
//   width: 1.2em;
//   height: 1.2em;
//   margin: 0 0.5em 0 -0.65em;
//   border-radius: 50%;
//   content: counter(li);
//   text-align: center;
//   background: lightgrey;
//   counter-increment: list;
// }
.history-item {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  &__status-circle-dot {
    width: 10px;
    height: 10px;
    border-radius: 20px;
  }

  &__status-circle-dot::after {
    border-left: 6px solid black;
    height: 100px;
  }
}
.history-item::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 55px;
  z-index: 1;
  height: 0px;
  width: 2px;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.16) 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: left;
  background-size: 2px 6px;
  background-repeat: repeat-y;
  border-radius: 20px;
}
.history-item:not(:last-child):after {
  height: calc(100% + 18px);
}
.map-container{
  // padding:10px;
   &__header {
    
    border-bottom: .5px solid lightgrey;
   }
   &__body {
    
   
   
   
   }
   &__footer {
    
   padding: 7px 0;
   }
}
