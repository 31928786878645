.dropdown {
  position: fixed;
  background-color: white;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: solid 1px #e4e4ed;
  overflow: auto;
  text-align: left;
  // transform-origin: top right;
  animation: grow-bottom cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s forwards;
  z-index: 9999999 !important;
  a {
    transform-origin: top left;
    color: inherit !important;
    text-decoration: none;
    font-size: 15px;
    animation: grow-bottom cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.5s forwards;
    // &:hover{
    //   background-color: #f4f5f5;
    // }
  }
  &__option {
    height: 40px;
    padding: 4px 14px;
    text-overflow: ellipsis;
    &:hover {
      background-color: var(--app-color-highlight);
      // border: 0.5px solid var(--app-color-highlight);
    }
  }
  &__padding {
    padding: 9px 0;
  }
}
// To center label text in Enp
.DropdownLabel .MuiInputLabel-outlined {
  transform: translate(14px, 17px) scale(1);
}

@keyframes grow-bottom {
  from {
    transform: scaleY(0) scaleX(0);
  }
  to {
    transform: scaleY(1) scaleX(1);
  }
}
.MuiInputLabel-outlined {
  background-color: #fff;
}
.MuiPopover-paper {
  max-width: 500px;
}
.MuiOutlinedInput-input {
  padding: 16px 14px !important;
}
.textExceed {
  text-overflow: ellipsis;
  overflow: hidden;
}
