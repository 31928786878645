@import url("//fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

.PageTitleContainer {
  padding: 13px 20px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 1.5;
  background-color: white;
  font-family: "Poppins" !important;
}
