.task-detail-tabs {
  &__info {
    padding: 20px;
    font-size: 14px;
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
    &__basic-detail {
      display: flex;
      padding-bottom: 20px;
      &__trackinglink {
        color: blue;
        cursor: pointer;
      }
      &__key-name {
        width: 35%;
        opacity: 0.7;
      }
      &__key-value {
        width: 65%;
        word-break: break-word;
      }
    }
    &__documentWithLabel {
      cursor: pointer;
      max-width: 3rem;
      text-align: center;
      display: inline-block;
      margin-right: 0.7rem;
    }
  }
  &__template-data {
    &__name {
      // font-size: 14px;
    }
    &__images {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      margin-right: 5px;
      border: 1px solid rgba(0, 0, 0, 0.28);
    }
  }
}
