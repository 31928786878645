.jobList {
  width: 100%;
  background-color: #f4f7fc;
  min-height: 75vh;
  &__notification {
    text-decoration: none;
    border-radius: 40px;
    padding: 8px 11px;
    position: relative;
    display: inline-flex;
    float: right;
    align-items: center;
    align-content: center;
  }
  &__showBulkActionButton {
    display: none;
  }
  &__showJobListActionButton {
    display: none;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  &__job-id {
    width: 110px;
    height: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    // opacity: 0.1;
    justify-content: space-evenly;
    border-radius: 2px;
    &:hover {
      cursor: pointer;
    }
    &__status-circle-dot {
      width: 10px;
      height: 10px;
      border-radius: 100px;
      position: absolute;
      left: 2px;
    }
    &__status-text {
      text-align: left;
      width: 100%;
      margin-left: 18px;
    }
  }
  &__auto-assignment-action {
    color: var(--primary-theme);
    display: flex;
    align-items: center;
    div {
      display: flex;
      align-items: center;
    }
    &:hover {
      cursor: pointer;
    }
    svg {
      margin-right: 6px;
    }
  }
  @media screen and (max-width: 1285px) {
    &__auto-assignment-action {
      color: var(--primary-theme);
      display: contents;
      align-items: center;
      div {
        display: contents;
        align-items: center;
      }
      &:hover {
        cursor: pointer;
      }
      svg {
        margin-right: 6px;
      }
    }
  }
}
.jobList .rdt_TableCell {
  padding-top: 20px;
  padding-bottom: 20px;
}
.jobList .rdt_TableHeader {
  display: none;
}

.jobList .badge {
  position: absolute;
  top: -7px;
  left: -2px;
  // padding: 2px 2px;
  // border-radius: 50%;
  // //background-color: red;
  // color: white;
}
.jobList .filterHeading {
  font-weight: normal;
  padding: 10px;
  font-size: larger;
}
// .filterButton:hover{
//   cursor:pointer;
// }
.jobList .CancelIcon:hover {
  cursor: pointer;
}
.jobList .filterButton:hover {
  cursor: pointer;
}
.jobList .filterButton {
  height: 20px;
  padding: 1px;
  color: var(--primary-theme);
  align-items: center;
  display: flex;
}
.jobList .headerColumn {
  overflow: "hidden";
  text-overflow: "ellipsis";
  white-space: "nowrap";
  display: "inline-block";
  max-width: "70px";
}
.jobList .headerColumn:hover {
  overflow: visible;
  white-space: normal;
  height: auto;
}
.headerColumn {
  // overflow: "hidden",
  // textOverflow: "ellipsis",
  // whiteSpace: "nowrap",
  // display: "inline-block",
  overflow: "hidden";
  text-overflow: "ellipsis";
  display: "-webkit-box";
  // webkitLineCla
  -webkit-line-clamp: 2;
  -webkit-box-orient: "vertical";
  // },
  max-width: "70px";
  // },
  &:hover + .hide {
    display: block;
    background-color: aqua;
    color: red;
  }
}
// .headerColumn:hover + .hide {

// }

// "&:hover": {
//   overflow: "visible",
//   display: "block",

//   whiteSpace: "normal",
//   height: "auto",
// },
.hide {
  display: "none!important";
}
