@mixin general-button {
  font-weight: 400;

  font-size: 16px;
  line-height: 23px;
  padding: 0px 30px;
  cursor: pointer;
  height: 46px;
  outline: none;
}

@mixin card-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 1em;
  border-radius: 10px;
  box-shadow: 1px 1px 9px 1px lightgrey;
}
@mixin input__radio {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
@mixin input__radio-field {
  border: 1px solid #e7e6e9;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
}
@mixin heading {
  font-size: 30px;
  line-height: 34px;
  font-weight: 500;
  text-align: center;
}
@mixin para {
  font-size: 16px;
  line-height: 17px;
  text-align: center;
}
@mixin heading1 {
  font-size: 36px;
  font-weight: 600;
  line-height: 56px;
}

@mixin heading2 {
  font-size: 30px;
  font-weight: 500;
  line-height: 46px;
}

@mixin heading3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
}

@mixin heading4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 34px;
}

@mixin heading5 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

@mixin para1 {
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
}
@mixin fullwidth {
  width: 100%;
}
