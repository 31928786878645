.Input-Templatefield {
  display: inline-block;
  width: 48% !important;
  margin-right: 20px;
}
.input__box-image {
  display: flex;
  /* flex-direction: column; */
  position: relative;
  margin-bottom: 8px;
  align-items: center;
}
