.rdt_TableHead {
  border: solid 0px #e9edf0;
  // min-width: 80%;
  // position: fixed;
  // height: 60px;
  // z-index: 10;
  // margin-top: 5px;
  // background-color: #f4f7fc;
}
.rdt_TableHeadRow {
  background-color: #f4f7fc !important;
  min-height: 50px;
}
.rdt_TableRow {
  min-height: initial !important;
  height: auto !important;
  //  overflow: hidden !important; /* Hide overflowing content */
  //  text-overflow: ellipsis !important; /* Display an ellipsis (...) when content overflows */
}

.rdt_TableCell {
  // white-space: nowrap; /* Prevent line breaks within cells */
  overflow: hidden !important; /* Hide overflowing content */
  text-overflow: ellipsis !important; /* Display an ellipsis (...) when content overflows */
  //max-height: 300px !important;
  //background-color: yellow !important;
}

.subscriber-data .rdt_TableBody {
  max-height: none !important;
}

.rdt_Pagination {
  background-color: #f4f7fc !important;
  border: none !important;
}
.rdt_TableCol {
  // color: #868686 !important;
  font-size: 14px !important;
}
.rdt_TableCell {
  font-size: 14px;
  color: #000000;
  font-weight: 500 !important;

  // padding-top: 20px;
  // padding-bottom: 20px;
}
.supervisorDatatable .rdt_TableCell,
.teamDatatable .rdt_TableCell {
  padding-top: 20px;
  padding-bottom: 20px;
}
.searchWord {
  margin: 20px;
}
.searchWord .MuiInputBase-inputTypeSearch {
  padding: 10px 15px;
}
.searchWord .MuiInputLabel-outlined {
  transform: translate(15px, 12px) scale(0.98);
}
.searchWord .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75);
}
.searchWord .input__box-container {
  margin-bottom: 0px;
}
.searchWord .MuiOutlinedInput-root {
  width: 300px;
  max-width: 100%;
}
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: white;
  //margin-right: 20px;
}
.rdt_TableHeader {
  display: none !important;
}
