.dropdown-pre-icon {
  margin-right: 10px;
  vertical-align: middle;
}
.dropdownOuter {
  padding: 5px 16px 5px 19.6px;
  border-radius: 25px;
  border: solid 1px #dadada;
}
.dropdownOuter .MuiMenu-paper {
  left: 280px !important;
}
