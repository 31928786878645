.Template {
  width: 100%;
  min-height: 90vh;

  .MuiCardHeader-action {
    margin-top: 0px;
  }
}
.NoRecord {
  padding: 0px 20px;
}
.template {
  &__subheading {
    padding: 20px 32px;
    align-self: flex-start;
    // opacity: 0.6;
  }
}
.customerList {
  width: 100%;
  min-height: 90vh;
  background-color: #f4f7fc;
  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    //margin-right: 20px;
  }
}
