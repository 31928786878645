.leaflet-label {
  background: rgb(235, 235, 235);
  background: rgba(235, 235, 235, 0.81);
  background-clip: padding-box;
  border-color: #777;
  border-color: rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border-style: solid;
  border-width: 4px;
  color: #111;
  display: block;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding: 1px 6px;
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  white-space: nowrap;
  z-index: 6;
}

.leaflet-label.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-label:before,
.leaflet-label:after {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  content: none;
  position: absolute;
  top: 5px;
}

.leaflet-label:before {
  // border-right: 6px solid black;
  // border-right-color: inherit;
  // left: -10px;
}

.leaflet-label:after {
  border-left: 6px solid black;
  border-left-color: inherit;
  right: -10px;
}

.leaflet-label-right:before,
.leaflet-label-left:after {
  content: "";
}

.leaflet-label {
  background-clip: inherit !important;
  background: rgba(0, 0, 0, 0.62) !important;
  color: #fff !important;
  border: 0px solid #a2a2a2 !important;
  border-radius: 50px !important;
  padding: 1px 8px !important;
  font-size: 11px !important;

  /* font: 12px/20px "Graphic-Medium" !important; */
  border-width: 1px !important;
}
