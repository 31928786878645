@import "./utils/mixins.scss";
// @import url("http://fonts.googleapis.com/css2?family=Loto:wght@400;500;600;700&display=swap");
// @import "~antd/dist/antd.css";
html,
body {
  // font-family: 'ProximaNova-Regular', Sans-Serif;
  font-family: "Lato";
  height: 100%;
  width: 100%;
  // overflow-x: hidden;
  position: relative;
}

.primary-button {
  margin: 10px 10px;
  cursor: pointer;
  @include general-button;
  background-color: var(--primary-theme);
  color: white;
  border: 1px solid var(--primary-theme);
  font-weight: 600;
  transition: 0.3s ease-in-out;
  &:disabled {
    background-color: #ebeff7;
    color: var(--heading-text-1);
    opacity: 0.5;
    border: 1px solid #ebeff7;
    font-weight: 600;
    cursor: not-allowed;
  }
}

.secondary-button {
  margin: 10px 7px;
  cursor: pointer;
  @include general-button;
  background-color: white;
  color: var(--primary-theme);
  border: 1px solid var(--primary-theme);
  font-weight: 500;
  transition: 0.3s ease-in-out;
  &:disabled {
    background-color: #ebeff7;
    color: var(--heading-text-1);
    opacity: 0.5;
    border: 1px solid #ebeff7;
    font-weight: 500;
    cursor: not-allowed;
  }
}

.primary-button:hover:enabled,
.secondary-button:hover:enabled {
  color: white;
  background-color: var(--secondary-theme);
  border: 1px solid var(--secondary-theme);
}

.back-default-button {
  cursor: pointer;
  @include general-button;
  color: var(--heading-text-1);
  background-color: #ebeff7;
  border: 1px solid #ebeff7;
  font-weight: 500;
  margin: 10px 10px;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.disabled-button {
  cursor: not-allowed;
}

.app {
  display: flex;
  &__outer {
    // width: calc(100vw - 240px);
    // margin-left: 240px;
    // padding: 1rem 1rem;
    width: 100%;
    background-color: var(--page-bg-color) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    overflow-x: hidden;
  }
}
.slide-rl-enter {
  transform: translateX(100%);
  transition: transform 1000ms ease-in-out;
}
.slide-rl-enter-active {
  transform: translateX(0%);
  transition: transform 1000ms ease-in-out;
}
.slide-rl-exit {
  display: none;
}
.slide-rl-exit-active {
  display: none;
}

.slide-lr-enter {
  transform: translateX(-100%);
  transition: transform 1000ms ease-in-out;
}
.slide-lr-enter-active {
  transform: translateX(0%);
  transition: transform 1000ms ease-in-out;
}
.slide-lr-exit {
  display: none;
}
.slide-lr-exit-active {
  display: none;
}

.ant-select {
  width: calc(100% - 2rem);
  min-height: 46px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(124, 119, 153, 0.3);
  @include para1;
  border-radius: 10px;
  color: #4b4279;
  font-weight: 400;
  // margin-top: 0.5rem;
}
.ant-select-selector {
  border: none;
  width: 100%;
  height: 100%;
  border: none;
  &:focus {
    border: none;
  }
}
.ant-select-multiple .ant-select-selector {
  border: none;
  width: 100%;
  height: 100%;
  border: none;
  &:focus {
    border: none;
  }
}

ant-select:not(.ant-select-disabled):hover {
  border: none;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}
.ant-select-arrow {
  top: 50%;
  right: 18px;
  width: 18px;
  height: 18px;
  margin-top: -6px;
  color: #16449b;
  font-size: 16px;
  line-height: 1;
}
.mainScreenHeading {
  padding: 0rem 0.5rem;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(21, 34, 50, 0.08);
}
.inputDropdownLabelColor .MuiInputLabel-shrink {
  background-color: #f4f7fc !important;
}
.inputDropdownLabelColor .MuiInputLabel-outlined {
  background-color: transparent;
}

@media screen and (max-width: 991px) {
  .Sidemenu {
    &__outer {
      display: none;
    }
  }
  .app {
    &__outer {
      width: 100%;
      margin: 0;
      overflow-x: hidden;
    }
  }
}
.MuiOutlinedInput-root {
  border-radius: 10px !important;
}

@media screen and (max-width: 580px) {
  .secondary-button {
    padding: 0px 15px;
    margin: 0px;
    height: 35px;
  }
}
::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 50px;
}
.MuiSelect-select:focus {
  background-color: transparent !important;
}
.MuiAutocomplete-inputRoot {
  padding: 0px !important;
}
.cke_notification_warning {
  display: none;
}
