// .dropdown-container{
//     background-color: var(--page-bg-color) !important;
// }
.dropdown-container .dropdown-content input {
  background-color: var(--page-bg-color);
}
.multi-select {
  --rmsc-main: var(--primary-theme) !important; //
  --rmsc-hover: #f1f3f5 !important;
  --rmsc-selected: #e2e6ea !important;
  --rmsc-border: #ccc !important;
  --rmsc-gray: #aaa !important;
  --rmsc-p: 10px !important; /* Spacing */
  --rmsc-radius: 10px !important; /* Radius */
  --rmsc-h: 50px !important; /* Height */
}
.dropdown-container .dropdown-content ul {
  overflow-y: auto;
  max-height: 120px;
}
.dropdown-container {
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  border: 1px solid #ccc;
  width: 100%;
  padding-left: 2px;
  background: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-weight: 400;
  border-radius: 10px;
  text-align: left;
  //background-color: white;
  //height: 40px;
}

.dropdown-container .gray {
  color: #696969 !important;
  font-size: 16px;
}
.dropdown-container .dropdown-content {
  z-index: 2;
}
