.drop {
  margin: 10px;
  margin-top: 0px;
}
.filterHeading {
  font-size: 18px;
}
.drop .dropdown-container {
  height: 50px;
}
.drop .dropdown-container {
  background-color: white;
  //border-color: grey;
}
.drop .dropdown-container .gray {
  font-size: 1rem;
}
.drop .dropdown-container .dropdown-heading {
  height: 5px;
  padding: 3px;
  margin-top: 20px;
}
.relativeFilter {
  width: 100%;
  display: flex;
}
.relativeFilter .input .MuiInputLabel-outlined {
  z-index: 1;
  background-color: transparent;
}
.relativeFilter .input {
  margin-right: 20px;
}
.relativeFilter .MuiOutlinedInput-root {
  height: 50px;
  background-color: white;
}
.relativeFilter .input .MuiFormControl-root {
  z-index: 1;
  background-color: transparent;
}
.relativeFilter .MuiInputLabel-outlined {
  background-color: white;
}
.filterSubHeading {
  margin: 10px 0px;
  font-weight: bold;
}
.button {
  justify-content: center;
  display: flex;
  width: 100%;
  // padding: 0px 10px;
}
