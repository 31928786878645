@import "../../utils/variables.scss";
// .react-datepicker__input-container input {
// width: calc(100% - 28px);
// padding: 18.5px 14px;
// height: 1.1876em;
// border-radius: 10px;
// border-color: rgba(0, 0, 0, 0.23);
// border-style: solid;
// border-width: 1px;
// color: var(--input-text-color);
// font-size: 16px;
// outline-color: var(--primary-theme);
// outline-offset: -1px;
// }
.react-datepicker-popper {
  width: max-content;
  z-index: 1000;
  padding-top: 0px !important;
}
.react-datepicker__time-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-datepicker__day--today {
  background-color: transparent;
  color: black;
}
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range,
.react-datepicker__day--in-range:hover,
.react-datepicker__day--selected,
.react-datepicker__time-list-item--selected {
  background-color: var(--primary-theme);
  color: white;
  border-radius: 0.9rem;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: var(--primary-theme);
  color: white;
  font-weight: bold;
}
.outlined {
  width: calc(100% - 28px);
  padding: 18.5px 14px;
  height: 0.7em;
  // margin-top: 6px;
  // margin-bottom: 6px;
  border-radius: 10px;
  border-color: rgba(0, 0, 0, 0.23);

  border-style: solid;
  border-width: 1px;
  color: var(--input-text-color);
  font-size: 16px;
  outline-color: var(--primary-theme);
  outline-offset: -1px;
  font-family: "Lato";
}

.standard {
  width: 90px;
  padding: 6px 0px 7px;
  height: 1.1876em;
  border-radius: 10px;
  border-color: rgba(0, 0, 0, 0.23);
  border-style: solid;
  border-width: 1px;
  color: var(--input-text-color);
  font-size: 16px;
  outline-color: var(--primary-theme);
  outline-offset: -1px;
  border: none;
  font-family: "Lato!important";

  outline: none;
}
.export-date-picker {
  width: calc(100% - 32px) !important;
  padding: 15px;
  height: 1.1876em;
  border-radius: 10px;
  border-color: rgba(0, 0, 0, 0.23);
  border-style: solid;
  border-width: 1px;
  color: var(--input-text-color);
  font-size: 16px;
  outline-color: var(--primary-theme);
  outline-offset: -1px;
}
.export-date-picker::placeholder {
  font-size: 16px;
  color: #696969;
  font-family: "Lato";
}
.standardRange {
  //width: 100%;
  width: 180px;
  padding: 6px 0px 7px;
  height: 1.1876em;
  border-radius: 10px;
  border-color: rgba(0, 0, 0, 0.23);
  border-style: solid;
  border-width: 1px;
  color: var(--input-text-color);
  font-size: 16px;
  outline-color: var(--primary-theme);
  outline-offset: -1px;
  border: none;
  outline: none;
}
.react-datepicker__triangle::before,
.react-datepicker__triangle::after {
  content: none !important;
}
.customHighlightedCss {
  background-color: rgb(248, 141, 0);
  border-radius: 0.9rem;
  font-weight: bolder;
  color: #ffff;
}
.customHighlightedCss:hover {
  background-color: rgb(248, 141, 0) !important;
  border-radius: 0.9rem;
  font-weight: bolder;
  color: #ffff;
}
.react-datepicker--time-only .react-datepicker__time-list {
  height: 200px !important;
}
