@import "../../utils/mixins.scss";
.large-logo {
  width: 200px;
  padding: 10px;
}
.sidebar {
  margin: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.sidebar span {
  font-family: "Avenir" !important;
  font-size: 1.125rem !important;
  font-weight: 600;
}

.sidebar ::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}


