.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: +10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  overflow-y: scroll;
  &__container {
    position: fixed;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__body {
    background: transparent;
  }
}
.modal-anim-enter {
  opacity: 0;
  transform: translateY(-100%);
}
.modal-anim-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition: opacity 500ms, transform 500ms;
}
.modal-anim-exit {
  opacity: 1;
  transform: translateY(0%);
}
.modal-anim-exit-active {
  opacity: 0;
  transform: translateY(+100%);
  transition: opacity 500ms, transform 500ms ease-in-out;
}

@media (max-width: 575px) {
  .modal__body {
    width: 90% !important;
  }
}

@media (min-width: 576px) and (max-width: 1024px) {
  .modal__body {
    width: 80% !important;
  }
}
@media (max-width: 575px) {
  .modal__body {
    width: 90% !important;
  }
}

@media (min-width: 576px) and (max-width: 1024px) {
  .modal__body {
    width: 80% !important;
  }
}
