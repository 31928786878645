.pageLoader {
  z-index: 9999;
  height: 100vh;
  //background-color: #897b7b4a;
  // background-color: black;
  // opacity: 0.2;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}
.datatable .pageLoader {
  background-color: transparent !important;
}
